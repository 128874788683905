import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'gatsby'
import Img from 'gatsby-image'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

class PageContentItem extends React.Component {
  render() {
    if(this.props.type === 'content') {
      var body = documentToReactComponents(JSON.parse(this.props.data.paragraphs.raw))
      return (
        <div>
          <h2>{this.props.data.heading}</h2>
          <div className={this.props.data.imageAlign}>
            <Img className="articlepage_heroimage" alt={this.props.data.heading} fluid={this.props.data.heroImage.fluid} />      
          </div>
          <div>{body}</div>
          <div style={{marginBottom:"5vmin"}}>
            <h4 className='navbutton' style={{color:"#ffffff"}}>
              <a href={'/'+this.props.data.slug} > Read More &#8250;</a>
            </h4>
          </div>
        </div>
      )
    } else if (this.props.type === 'news') {
      return (
        <Link to={`${this.props.data.slug}`}>
          <div className="article_preview" style={{marginBottom:"5vmin"}}>          
            <div>
              <Img className="article_preview_img" alt={this.props.data.title} fluid={this.props.data.heroImage.fluid} />      
            </div>
            <h2>{this.props.data.title}</h2>
            <small style={{color:'#0090ff'}}>{this.props.data.publishDate}</small>
            <p style={{color:'#909090'}}
            dangerouslySetInnerHTML={{
                __html: this.props.data.description.description,
            }}
            />  
            <div >
              <h4 className='navbutton' style={{color:"#ffffff"}}>Read More &#8250;</h4>
            </div>
          </div>
        </Link>     
      )
    }
  }
}

PageContentItem.propTypes = {
  data: PropTypes.object  
}

export default PageContentItem
