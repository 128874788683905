import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'
import Layout from '../components/layout'
import Header from '../components/Header'
import Main from '../components/Main'
import Footer from '../components/Footer'

class IndexPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isArticleVisible: false,
      timeout: false,
      articleTimeout: false,
      article: '',
      loading: 'is-loading'
    }
    this.handleOpenArticle = this.handleOpenArticle.bind(this)
    this.handleCloseArticle = this.handleCloseArticle.bind(this)
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
        this.setState({loading: ''});
    }, 100);
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount () {
    if (this.timeoutId) {
        clearTimeout(this.timeoutId);
    }
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  setWrapperRef(node) {
    this.wrapperRef = node;
  }

  handleOpenArticle(article) {

    this.setState({
      isArticleVisible: !this.state.isArticleVisible,
      article
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        articleTimeout: !this.state.articleTimeout
      })
    }, 350)

  }

  handleCloseArticle() {

    this.setState({
      articleTimeout: !this.state.articleTimeout
    })

    setTimeout(() => {
      this.setState({
        timeout: !this.state.timeout
      })
    }, 325)

    setTimeout(() => {
      this.setState({
        isArticleVisible: !this.state.isArticleVisible,
        article: ''
      })
    }, 350)

  }

  handleClickOutside(event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      if (this.state.isArticleVisible) {
        this.handleCloseArticle();
      }
    }
  }

  render() {    
    var all_content = this.props.data.allContentfulPageContent.edges;    
    var servicescontent = []
    var all_newsarticles = this.props.data.allContentfulNewsArticle.edges;    
    var newsarticles = [] 
    for (var i = 0; i < all_content.length; i++) {      
      var id_exists = false;
      for (var j =0; j < servicescontent.length; j++) { if (all_content[i].node.slug === servicescontent[j].node.slug) { id_exists = true;}}
      if (id_exists === false) { servicescontent.push(all_content[i])}
    }
    for (var i = 0; i < all_newsarticles.length; i++) {      
      var id_exists = false;
      for (var j =0; j < newsarticles.length; j++) {if (all_newsarticles[i].node.slug === newsarticles[j].node.slug) {id_exists = true;}}
      if (id_exists === false) { newsarticles.push(all_newsarticles[i])}
    }
    servicescontent.reverse(function (a, b) { return b.displayIndex - a.displayIndex; });    
    return (
      <Layout location={this.props.location}>
        <div className={`body ${this.state.loading} ${this.state.isArticleVisible ? 'is-article-visible' : ''}`}>
          <div id="wrapper">
            <Header onOpenArticle={this.handleOpenArticle} timeout={this.state.timeout} />
            <Main
              isArticleVisible={this.state.isArticleVisible}
              timeout={this.state.timeout}
              articleTimeout={this.state.articleTimeout}
              article={this.state.article}
              onOpenArticle={this.handleOpenArticle} 
              onCloseArticle={this.handleCloseArticle}
              setWrapperRef={this.setWrapperRef}
              servicesContent = {servicescontent}
              newsArticles = {newsarticles}
            />
            <Footer timeout={this.state.timeout} />
          </div>
          <div id="bg"></div>
        </div>
      </Layout>
    )
  }
}

export default IndexPage
export const pageQuery = graphql`
query IndexQuery {
  site {
    siteMetadata {
      title
      description
    }
  }
  allContentfulPageContent(filter: {site: {eq: "tententhsdigital"}}) {
    edges {
      node {
        heroImage {
          fluid {
            src
            srcSet
          }
        }
        heading
        displayIndex
        id
        imageAlign
        paragraphs {
          raw
        }
        siteSection
        slug
      }
    }
  }
  allContentfulNewsArticle(filter: {site: {eq: "tententhsdigital"}}) {
    edges {
      node {
        title
        publishDate(formatString: "MMMM Do, YYYY")
        slug
        description{
          description
        }
        heroImage {
          fluid {
            src
            srcSet
          }
        }        
        bodyRichText {
          raw
        }
      }
    }
  }
}
`




