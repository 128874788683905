import PropTypes from 'prop-types'
import React from 'react'
import PageContentItem from './PageContentItem'
import baselogo from '../images/logo_base.png'
const USER_API = process.env.API_ENDPOINT

class Main extends React.Component {
  constructor(props) {
    super(props);
  } 
  state = {
    name: "",
    phone: "",
    email: "",
    message: ""
  }
  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name
    this.setState({
      [name]: value,
    })
  }
  handleSubmit = event => {        
    event.preventDefault()
    let formData = {};
    formData['name']= this.state.name;
    formData['email']= this.state.email;
    formData['phone']= this.state.phone;
    formData['subject']= "Form";
    formData['desc']= this.state.message;
    
    var requestConfig = {
        method: 'POST',
        body: JSON.stringify(formData),        
        mode: "cors",
        cache: "no-cache",
        headers: new Headers({
          "Content-Type": "application/json"
        })
    };
    console.log(JSON.stringify(formData))
    fetch(USER_API, requestConfig).then(response => response.json()            
    ).then(data => {
        console.log(data);        
        this.props.onOpenArticle('thankyou')
    }).catch(error => {
        console.log(error);
    });
  }
  resetform = event =>{
    this.setState({
      name: "",
      phone: "",
      email: "",
      message: ""
    })
  }

  render() {
    let close = <div className="close" role="button" tabIndex="0" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} ></div>
    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? { display: 'flex' } : { display: 'none' }} >
        <article id="about" className={`${this.props.article === 'about' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">About</h2>          
          <p>TenTenths Digital is a bespoke agency who work with businesses of all sizes to harness the power of digital marketing. This is achieved by providing AI-backed services for web optimisation, search ranking improvement and product delivery - TenTenths will work with you to discover the best solution based on your industry needs.</p>
          <p>TenTenths Digital provides artificial intelligence backed digital media services that help businesses optimize their business potential and maximise their engagement with clients and industry participants. TenTenths foster an environment where the best ideas win as it leads to a greater understanding of how their client’s industry works and how to most effectively achieve their marketing goals.</p>
          <div className="wrapper_logo_container" >
            <img className="wrapper_logo" src={baselogo} alt="" />
          </div>
          {close}
        </article>
        <article id="services" className={`${this.props.article === 'services' ? 'active' : ''} ${ this.props.articleTimeout ? 'timeout' : '' }`} style={{ display: 'none' }}>
          <h2 className="major">Services</h2>
          <div>
            {this.props.servicesContent.map((elem, index) => (              
              <div>
                <PageContentItem key={index} data={elem.node} type="content"/>                                  
              </div>
            ))}
            <div className="wrapper_logo_container" >
              <img className="wrapper_logo" src={baselogo} alt="" />
            </div>
          </div>          
          {close}
        </article>
        <article id="blog" className={`${this.props.article === 'blog' ? 'active' : ''} ${ this.props.articleTimeout ? 'timeout' : '' }`} style={{ display: 'none' }} >
          <h2 className="major">Blog</h2>
          <div>
            <ul className="article-list" style={{listStyleType:"none"}}>
            {this.props.newsArticles.map((elem, index) => (              
              <li>
                <PageContentItem key={index} data={elem.node} type="news" />                                  
              </li>
            ))}
            </ul>
            <div className="wrapper_logo_container" >
              <img className="wrapper_logo" src={baselogo} alt="" />
            </div>
          </div>
          {close}
        </article>
        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${ this.props.articleTimeout ? 'timeout' : '' }`} style={{ display: 'none' }} >
          <h2 className="major">Contact</h2>
          <form id="contact-form" method="post" action='#' onSubmit={this.handleSubmit}>
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name-input" value={this.state.name} onChange={this.handleInputChange}/>
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email-input" value={this.state.email} onChange={this.handleInputChange}/>
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="description-input" rows="4" value={this.state.message} onChange={this.handleInputChange}></textarea>
            </div>
            <ul className="actions">
              <li>
                <input type="submit" value="Send Message" className="special" onClick={() => { this.props.onCloseArticle() }} onKeyDown={() => { this.props.onCloseArticle() }} />
              </li>
              <li>
                <input type="reset" value="Reset" onClick={() => { this.resetform() }} onKeyDown={() => { this.resetform() }} />
              </li>
            </ul>
          </form>
          <ul className="icons">
            <li>
              <a href="https://twitter.com/TententhsD" className="icon fa-twitter" >
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="https://www.facebook.com/TenTenths-Digital-264562015446914" className="icon fa-facebook">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="https://www.instagram.com/tentenths.digital/" className="icon fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a href="https://linkedin.com" className="icon fa-linkedin" >
                <span className="label">LinkedIn</span>
              </a>
            </li>
          </ul>
          <ul className="article-list">
            <li>
              <a href="/privacy"><span className="legalnavitems">Privacy Policy</span></a>              
            </li>
            <li>
              <a href="/terms"><span className="legalnavitems">Terms &amp; Conditions</span></a>              
            </li>
          </ul>
          <div className="wrapper_logo_container" >
            <img className="wrapper_logo" src={baselogo} alt="" />            
          </div>
          {close}
        </article>
        <article id="thankyou" className={`${this.props.article === 'thankyou' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{ display: 'none' }}>
          <h2 className="major">Thank You</h2>          
          <p>Thank you for sending us a message, we love hearing from you. You will receive a confirmation email shortly and we will endevour to get back to you as soon as possible.</p>          
          <div className="wrapper_logo_container" >
            <img className="wrapper_logo" src={baselogo} alt="" />
          </div>
          {close}
        </article>
      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onOpenArticle: PropTypes.func,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
  servicesContent: PropTypes.array,
  newsArticles: PropTypes.array
}

export default Main
